/*---------------- css loader ----------------*/
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@keyframes infinite-blink {
  0% {
    opacity: 1;
    top: 0;
  }
  30% {
    opacity: 1;
    top: 30%;
  }
  70% {
    opacity: 0;
    top: 30%;
  }
  100% {
    opacity: 0;
    top: 0;
  }
}


/*============== play-button-pulse ==============*/
@-webkit-keyframes play-button-pulse {
  0% {
    @include transform(scale(0.5));
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    @include transform(scale(1.2));
    opacity: 0;
  }
}
@-moz-keyframes play-button-pulse {
  0% {
    @include transform(scale(0.5));
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    @include transform(scale(1.2));
    opacity: 0;
  }
}
@-ms-keyframes play-button-pulse {
  0% {
    @include transform(scale(0.5));
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    @include transform(scale(1.2));
    opacity: 0;
  }
}
@-o-keyframes play-button-pulse {
  0% {
    @include transform(scale(0.5));
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    @include transform(scale(1.2));
    opacity: 0;
  }
}
@keyframes play-button-pulse {
  0% {
    @include transform(scale(0.5));
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    @include transform(scale(1.2));
    opacity: 0;
  }
}

/*== heartBeat ==*/
@keyframes heartBeat {
  0% {
    @include transform(scale(1));
  }
  25% {
    @include transform(scale(1.1));
  }
  40% {
    @include transform(scale(1));
  }
  60% {
    @include transform(scale(1.04));
  }
  100% {
    @include transform(scale(1));
  }
}

@keyframes cluster-animation{
  0%,100%{
    @include box-shadow(0 0 0 4px rgba(0,0,0,0.1));
  }
  50%{
    @include box-shadow(0 0 0 7px rgba(0,0,0,0.1));
  }
}

/*====== starsAnimation ========*/
@keyframes starsAnimation {
  from {
    transform: translateY(-2000px);
  }
  to {
    transform: translateY(0px);
  }
}

/*====== background-move ========*/
@keyframes background-move {
  0% { background-position: 0 0, 0 0; }
  100% {
    background-position: 100vw 0, 0 0;
    background-position: unquote('max(100vw, 40em)')  0, 0 0;
  }
}
