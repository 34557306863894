/*======= card ========*/
.card-item {
  margin-bottom: 30px;
  @include border-radius(5px);
  background-color: $white;
  @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
  @include transition(0.3s);
  position: relative;
  .card-img {
    position: relative;
    @include border-radius(5px);
    img {
      width: 100%;
      @include border-radius(5px);
      @include transition(0.3s);
    }
    .badge {
      position: absolute;
      background-color: $theme-color-5;
      top: -10px;
      left: 20px;
      padding: 6px 11px;
      font-size: $font-size-12;
      text-transform: uppercase;
      @include border-radius(30px);
      font-weight: $font-weight-medium;
      z-index: 1;
      color: $white;
      @include transition(0.3s);
    }
    .badge-ribbon {
      left: auto;
      right: 20px;
      background-color: $color-5;
    }
    .add-to-wishlist {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 35px;
      height: 35px;
      line-height: 35px;
      font-size: $font-size-18;
      cursor: pointer;
      color: $theme-color;
      background-color: $white;
      @include transition(0.3s);
      &:hover {
        background-color: $theme-color-2;
        color: $white;
      }
    }
  }
  .card-title {
    font-size: $font-size-19;
    color: $theme-color;
    margin-bottom: 2px;
    a {
      color: $theme-color;
      @include transition(0.3s);
      &:hover {
        color: $theme-color-2;
      }
    }
  }
  .card-meta {
    font-size: $font-size-15;
    .post-dot {
      width: 4px;
      height: 4px;
      background-color: rgba(128, 137, 150, 0.4);
      @include border-radius(50%);
      display: inline-block;
      margin-right: 5px;
      margin-left: 5px;
      position: relative;
      top: -3px;
    }
  }
  .card-body {
    padding: 25px 30px;
    min-height: auto;
  }
  .list-items-2 {
    li {
      span {
        @media #{$small_mobile_four} {
          width: 100%;
        }
      }
    }
  }
  .card-rating {
    padding-top: 12px;
    padding-bottom: 12px;
    .badge {
      background-color: $color-5;
      font-size: $font-size-15;
    }
    .review__text {
      padding-left: 4px;
      padding-right: 4px;
      color: $color-5;
      font-size: $font-size-15;
    }
    .rating__text {
      font-size: $font-size-14;
    }
  }
  .card-price {
    font-size: $font-size-15;
    .price__from {
      font-size: $font-size-13;
    }
    .price__num {
      color: $theme-color;
      font-size: $font-size-18;
      font-weight: $font-weight-medium;
    }
    .price__text {
      display: block;
      line-height: 16px;
    }
    @media #{$laptop_m_four} {
      display: block !important;
    }
     @media #{$tab_device} {
      display: flex !important;
    }
    @media #{$large_mobile} {
      display: flex !important;
    }
  }
  &:hover {
    @include transform(translateY(-3px));
  }
}

/*======= destination-card ========*/
.destination-card {
  position: relative;
  overflow: hidden;
  .card-img {
    .badge {
      top: 20px;
      @include border-radius(5px);
      background-color: $theme-color-2;
    }
    .badge-ribbon {
      background-color: $color-5;
    }
    &::before {
      background-color: $theme-color;
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      z-index: 1;
      @include border-radius(5px);
    }
  }
  .card-body {
    position: absolute;
    bottom: -50px;
    width: 100%;
    z-index: 2;
    @include transition(0.3s);
  }
  .card-title {
    color: $white;
    margin-bottom: 0;
    a {
      color: $white;
      &:hover {
        color: $white;
      }
    }
  }
  .card-rating {
    padding-top: 4px;
    padding-bottom: 5px;
    .rating__text {
      color: $white;
    }
  }
  .tour__text {
    font-weight: $font-weight-medium;
  }
  .card-price {
    color: $white;
    .price__num {
      color: $white !important;
    }
  }
  &:hover {
    .card-body {
      bottom: 0;
    }
  }
}
/*======= destination--card ========*/
.destination--card {
  .card-body {
    bottom: 0;
    .theme-btn {
      margin-right: 10px;
      opacity: 0;
      visibility: hidden;
    }
  }
  .card-title {
    font-size: $font-size-22;
    margin-bottom: 2px;
  }
  .card-meta {
    font-weight: $font-weight-bold;
    color: $white;
  }
  &:hover {
    .card-body {
      .theme-btn {
        opacity: 1;
        visibility: visible;
        margin-right: 0;
      }
    }
  }
}

/*======= car-card ========*/
.car-card {
  .card-img {
    overflow: hidden;
    img {
      @include transition(0.3s);
    }
    .badge {
      top: 20px;
    }
    &:hover {
      img {
        @include transform(scale(1.04));
      }
    }
  }
  .card-rating {
    padding-bottom: 14px;
  }
  .card-body {
    padding-top: 10px;
  }
  .card-title {
    margin-bottom: 0;
    margin-top: 2px;
  }
  &:hover {
    @include transform(translateY(0));
  }
}

.card-attributes {
  padding-bottom: 12px;
  ul {
    li {
      margin-right: 15px;
      padding-right: 15px;
      border-right: 1px solid rgba(128, 137, 150, 0.1);
      color: $theme-color;
      &:last-child {
        border-right: none;
      }
      i {
        padding-right: 7px;
        font-size: $font-size-22;
      }
    }
  }
}
/*======= trending-card ========*/
.trending-card {
  @include border-radius(12px);
  .card-img {
    overflow: hidden;
    @include border-radius(12px);
    .badge {
      top: 20px;
      left: 0;
      @include border-radius(0 30px 30px 0);
      &.badge-ribbon {
        left: auto;
        right: 0;
        @include border-radius(30px 0 0 30px);
      }
    }
    img {
      @include transition(0.3s);
      @include border-radius(12px);
    }
    &:hover {
      img {
        @include transform(scale(1.1));
      }
    }
  }
  &:hover {
    @include transform(translateY(0));
  }
}

/*======= cruise-card ========*/
.cruise-card {
  position: relative;
  .card-img {
    &::before {
      background-color: $theme-color;
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      z-index: 1;
      @include border-radius(5px);
    }
  }
  .card-body {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
  }
  .card-title {
    color: $white;
    text-align: right;
    font-size: $font-size-26;
    font-weight: $font-weight-black;
    line-height: 35px;
    a {
      color: $white;
      &:hover {
        color: $white;
      }
    }
    span {
      display: block;
    }
  }
  .card-footer {
    background-color: transparent;
    border: none;
    @include border-radius(0);
  }
  .card-subtitle {
    color: $theme-color;
    font-size: $font-size-17;
    font-weight: $font-weight-bold;
    margin-top: 0;
  }
}

/*======= cruise--card ========*/
.cruise--card {
  border: 1px solid rgba(128, 137, 150, 0.1);
  .card-body {
    .cruise-logo {
      width: auto;
      margin-bottom: 10px;
    }
  }
  &:hover {
    @include transform(translateY(0));
  }
}

/*======= cruise-heading ========*/
.cruise-heading {
  background-color: rgba(40, 125, 250, 0.1);
  padding: 20px;
  @include border-radius(5px);
  margin-bottom: 20px;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    bottom: -10px;
    left: 20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgba(40, 125, 250, 0.1);
  }
}
/*======= cruise-line-list ========*/
.cruise-line-list {
  column-count: 2;
  @media #{$tab_device} {
    margin-bottom: 30px;
  }
   @media #{$large_mobile} {
    margin-bottom: 30px;
  }
   @media #{$small_mobile} {
    margin-bottom: 30px;
  }
  @media #{$small_mobile_five} {
    column-count: 1;
  }
}

/*======= flight-card ========*/
.flight-card {
  .card-body {
    img {
      margin-bottom: 8px;
    }
  }
  .card-price {
    padding-top: 12px;
  }
}
/*======= flight--card ========*/
.flight--card {
  padding: 20px;
  border: 1px solid rgba(128, 137, 150, 0.2);
  .card-img {
    background-color: rgba(128, 137, 150, 0.1);
    text-align: center;
    padding: 50px 30px;
    img {
      width: auto;
    }
  }
  .card-body {
    padding: 20px 0 0 0;
  }
}

.flight-time{
  border-top: 1px solid rgba(128, 137, 150, 0.1);
}

.flight-time-item {
  border-bottom: 1px solid rgba(128, 137, 150, 0.1);
  padding-top: 15px;
  padding-bottom: 15px;
  .la {
    color: $theme-color-2;
    font-size: $font-size-26;
  }
}

.take-off {
  .la {
    @include transform(rotate(45deg));
  }
}

.landing {
  .la {
    @include transform(rotate(120deg));
  }
}

/*====  deal-list ========*/
.deal-list {
  padding: 12px 20px;
  @include border-radius(5px);
  background-color: $white;
  @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
  border: 1px solid rgba(128, 137, 150, 0.2);
  @include transition(0.3s);
  margin-bottom: 30px;
  .title {
    a {
      color: $theme-color;
      @include transition(0.3s);
      &:hover {
        color: $theme-color-2;
      }
    }
  }
  &:hover {
    border-color: $theme-color-2;
    @include transform(translateY(-3px));
  }
}

/*======= blog-card ========*/
.blog-card {
  .card-img {
    img {
      @include border-radius(5px 5px 0 0);
    }
    .post-format {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 35px;
      height: 35px;
      line-height: 35px;
      font-size: $font-size-18;
      color: $theme-color;
      background-color: $white;
      z-index: 1;
    }
    &::before {
      background-color: $theme-color;
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.7;
      z-index: 1;
      @include border-radius(5px 5px 0 0);
    }
  }
  .card-body {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    padding-bottom: 20px;
  }
  .card-footer {
    background-color: transparent;
    border-top: none;
    position: relative;
    padding: 20px;
    &:last-child {
      @include border-radius(0);
    }
    .author-content {
      .author-img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
    }
  }
  .post-categories {
    padding-bottom: 14px;
    .badge {
      text-transform: capitalize;
      background-color: $white;
      color: $theme-color;
      font-size: $font-size-13;
      margin-right: 5px;
      padding: 4px 8px;
      font-weight: $font-weight-regular;
      &:hover {
        color: $theme-color-2;
      }
    }
  }
  .card-meta,
  .card-title {
    color: $white;
    a {
      color: $white;
    }
  }
  .card-title {
    margin-bottom: 5px;
    a {
     &:hover {
       color: $white;
     }
    }
  }
  .card-meta {
    .post-dot {
      background-color: $white;
    }
  }
}
/*===== post-share ======*/
.post-share {
  ul {
    li {
      position: relative;
      .icon-element {
        width: 40px;
        height: 40px;
        line-height: 40px;
        background-color: rgba(40, 125, 250, 0.1);
        cursor: pointer;
        font-size: $font-size-20;
        color: $theme-color-2;
        &:hover {
          background-color: $theme-color-2;
          color: $white;
        }
      }
      .post-share-dropdown {
        position: absolute;
        top: 0;
        right: 42px;
        background-color: $white;
        @include box-shadow(0 0 40px rgba(82, 85, 90, 0.3));
        @include transition(0.3s);
        @include border-radius(5px);
        opacity: 0;
        visibility: hidden;
        &::before {
          position: absolute;
          content: "";
          top: 50%;
          right: -4px;
          width: 8px;
          height: 8px;
          background-color: $white;
          @include transform(translateY(-50%) rotate(45deg));
        }
        li {
          display: inline-block;
          a {
            padding: 5px 6px;
            display: block;
            color: $theme-color;
            @include transition(0.3s);
            &:hover {
              color: $theme-color-2;
            }
          }
        }
      }
      &:hover {
        .post-share-dropdown {
          right: 47px;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

/*======= blog-card-layout-2 ========*/
.blog-card-layout-2 {
  @include transition(0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55));
  .post-categories {
    .badge {
      border: 1px solid rgba(128, 137, 150, 0.2);
      @include border-radius(30px);
      &:hover {
        border-color: $theme-color-2;
      }
    }
  }
  .card-body {
    position: inherit;
    top: auto;
    left: auto;
  }
  .card-title {
    color: $theme-color;
    a {
      color: $theme-color;
      &:hover {
        color: $theme-color-2;
      }
    }
  }
  .card-meta {
    color: $theme-color-4;
    .post-dot {
      background-color: rgba(128, 137, 150, 0.5);
    }
  }
  .card-footer {
    padding-top: 0;
  }
}

/*======= card-item-list ========*/
.card-item-list {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  @media #{$large_mobile} {
    display: block;
  }
  @media #{$small_mobile} {
    display: block;
  }
  .card-img-carousel {
    width: 33%;
    @media #{$large_mobile} {
      width: 100%;
    }
    @media #{$small_mobile} {
      width: 100%;
    }
    .card-img {
      img {
        height: 310px;
        @media #{$tab_device} {
          height: 358px;
        }
         @media #{$large_mobile} {
          height: auto;
        }
        @media #{$small_mobile} {
          height: auto;
        }
      }
    }
  }
  .card-img {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    @include border-radius(5px 0 0 5px);
    @media #{$large_mobile} {
      @include border-radius(5px 5px 0 0);
    }
    @media #{$small_mobile} {
      @include border-radius(5px 5px 0 0);
    }
    img {
      @include border-radius(5px 0 0 5px);
      object-fit: cover;
      height: 223px;
      @media #{$large_mobile} {
        height: auto;
         @include border-radius(5px 5px 0 0);
      }
      @media #{$small_mobile} {
        height: auto;
         @include border-radius(5px 5px 0 0);
      }
    }
  }
  .card-body {
    -webkit-box-flex: 2;
    -webkit-flex: 2;
    -ms-flex: 2;
    flex: 2;
  }
}
/*======= card-item--list ========*/
.card-item--list {
  position: relative;
  .card-img {
   img {
     height: 270px;
   }
  }
  &:hover {
    @include transform(translateY(0));
  }
}
/*==== card-item-list-2 ========*/
.card-item-list-2 {
  .card-img {
    @include border-radius(5px);
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    img {
      @include border-radius(5px);
      height: auto;
    }
  }
  .card-body {
    padding-top: 0;
    padding-left: 20px;
    @media #{$large_mobile} {
      padding-left: 0;
      padding-top: 20px;
    }
    @media #{$small_mobile} {
      padding-left: 0;
      padding-top: 20px;
    }
  }
}
/*======= user-card ========*/
.user-card {
  @include box-shadow(0 0 0 0);
  border: 1px solid rgba(128, 137, 150, 0.2);
  @media #{$tab_device} {
    display: block !important;
  }
  @media #{$large_mobile} {
    display: block !important;
  }
  @media #{$small_mobile} {
    display: block !important;
  }
  .card-body {
    .d-flex {
      @media #{$large_mobile} {
        display: block !important;
      }
      @media #{$small_mobile} {
        display: block !important;
      }
    }
  }
  .list-items.list-items-2 {
    @media #{$large_mobile} {
      margin-bottom: 15px;
    }
    @media #{$small_mobile} {
      margin-bottom: 15px;
    }
  }
  &:hover {
    @include transform(translateY(0));
  }
}
/*======= recent-post-card ========*/
.recent-post-card {
  padding: 10px;
  margin-bottom: 20px;
  @media #{$large_mobile} {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
  }
  @media #{$large_mobile_three} {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
  }
  @media #{$small_mobile_five} {
    display: block;
  }
  .card-img {
    img {
      height: auto;
      @include border-radius(4px);
    }
  }
  .card-body {
    padding: 0 0 0 10px;
    @media #{$small_mobile_five} {
      padding-top: 10px;
      padding-left: 0;
    }
  }
  .card-title {
    font-size: $font-size-15;
  }
  .card-meta {
    font-size: $font-size-14;
  }
  &:hover {
    @include transform(translateY(0));
  }
}
/*======= blog-single-card ========*/
.blog-single-card {
  @include box-shadow(0 0 0 0);
  @include border-radius(0);
  .card-img {
    img {
      @include border-radius(5px);
    }
  }
  &:hover {
    @include transform(translateY(0));
  }
}

/*======= profile-card ========*/
.profile-card {
  @include box-shadow(0 0 0 0);
  border: 1px solid rgba(128, 137, 150, 0.1);
  &:hover {
    @include transform(translateY(0));
  }
}

/*================== booking-detail-form ===================*/
.booking-detail-form {
  .card-item {
    @include transform(translateY(0));
    .list-items {
      li {
        span {
          @media #{$small_mobile_four} {
            width: 150px;
          }
        }
      }
    }
  }
}

/* room-card */
.room-card {
  .card-attributes {
    ul {
      @media #{$small_mobile_four} {
        flex-wrap: wrap;
      }
    }
  }
  &:hover {
    @include transform(translateY(0));
    z-index: 2;
  }
}
/* card-img-carousel */
.card-img-carousel {
  .owl-nav {
    @include transition(0.3s);
    opacity: 0;
    visibility: hidden;
  }
  .owl-dots {
    padding-top: 0 !important;
    position: absolute;
    bottom: 10px;
  }
  &:hover {
    .owl-nav {
      opacity: 1;
      visibility: visible;
    }
  }
}
/* gallery-card */
.gallery-card {
  overflow: hidden;
  margin-bottom: 30px;
  img {
    width: 100%;
    @include transition(0.3s);
    &:hover {
      @include transform(scale(1.1));
    }
  }
}

/* grid-masonry-item */
.grid-masonry-item {
  img {
    object-fit: cover;
  }
}

.grid-masonry-item-width-1 {
  img {
    height: 350px;
  }
}
.grid-masonry-item-width-2 {
  img {
    height: 450px;
  }
}
.grid-masonry-item-width-3 {
  img {
    height: 500px;
  }
}
.grid-masonry-item-width-4 {
  img {
    height: 400px;
  }
}
/*======= flip-box-item ==========*/
.flip-box {
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  perspective: 1000px;
  -webkit-perspective: 1000px;
  @include border-radius(8px);
  margin-bottom: 30px;
  .flip-img {
    @include border-radius(8px);
    width: 100%;
  }
  .flip-content {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 25px;
    -webkit-perspective: inherit;
    perspective: inherit;
    z-index: 2;
    @include transform(translateZ(70px) scale(.90));
    color: $white;
  }
  .flip-title {
    color: $white;
    font-size: $font-size-20;
  }
  .flip-subtitle {
    font-weight: $font-weight-semi-bold;
  }
  &:hover{
    .flip-box-front {
      @include transform(rotateY(-180deg));
      -webkit-transform-style: preserve-3d;
      -ms-transform-style: preserve-3d;
      transform-style: preserve-3d;
    }
    .flip-box-back {
      @include transform(rotateY(0deg));
      -webkit-transform-style: preserve-3d;
      -ms-transform-style: preserve-3d;
      transform-style: preserve-3d;
    }
  }
}

.flip-box-front,
.flip-box-back {
  @include border-radius(8px);
  -ms-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
  transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
  -webkit-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $theme-color;
    @include border-radius(8px);
    opacity: 0.7;
  }
}
.flip-box-front {
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.flip-box-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  @include transform(rotateY(180deg));
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}